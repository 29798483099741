import { setTrackFromPlaylist } from '../../6_utils/helpers';
import MEDIA_STATE_ACTIONS from './MediaState.actions';

const mediaStateReducer = (state, action) => {
    switch (action.type) {
        case MEDIA_STATE_ACTIONS.TOGGLE_PLAY:
            return {
                ...state,
                isPlaying: state.isPlaying == true ? false : true
            }
        case MEDIA_STATE_ACTIONS.PLAY_NEXT:
            return {
                ...state,
                // playlistIndex,
                // prevTrack: state.currentTrack,
                // currentTrack: state.nextTrack,
                ...setTrackFromPlaylist(
                    {url: state.nextTrack.url},
                    state.projectPlaylist.length > 0 ? 
                        state.projectPlaylist.filter(track => track.mime_type.includes('audio')) 
                    : 
                        state.playlist.filter(track => track.mime_type.includes('audio')), 
                        {url: state.currentTrack.url}
                    )
            }
        case MEDIA_STATE_ACTIONS.PLAY_PREV:
            return {
                ...state,
                ...setTrackFromPlaylist(
                    {url: state.prevTrack.url},
                    state.projectPlaylist.length > 0 ? 
                        state.projectPlaylist.filter(track => track.mime_type.includes('audio')) 
                    : 
                        state.playlist.filter(track => track.mime_type.includes('audio'))
                    )
            }
        
        case MEDIA_STATE_ACTIONS.CHANGE_VOLUME:
            return {
                ...state,
                volume: changeVolume(state.volume, action.payload)
            }
        case MEDIA_STATE_ACTIONS.SET_TRACK:
            return {
                ...state,
                isPlaying: true,
                ...setTrackFromPlaylist(action.payload, state.playlist),
            }
        case MEDIA_STATE_ACTIONS.SET_MEDIA_STATE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

const changeVolume = (volume, method) => {

    const factor = volume > 0.2 ? 0.1 : 0.02;

    if (method == 'up') {
        return volume + factor < 1 ? volume + factor : 1;
    } else if (method == 'down') {
        return volume - factor > 0 ? volume - factor : 0;
    }

}

// const selectTrack = (state, method, playlistIndex) => {

//     const playlist = state.playlist;
//     let returnValue = {};
//     console.log('spasst')
//     return {}

//     if (method === 'next') {
//         let playlistItem = playlist[playlistIndex] ? playlist[playlistIndex] : playlist[0]
//         console.log(playlistIndex, playlist, playlistItem);
//         returnValue = {
//             // name: playlistItem.title.rendered,
//             // totalTime: playlistItem.media_details.length,
//             // type: playlistItem.mime_type === 'audio/mpeg' ? 'audio' : 'video',
//             // url: playlistItem.source_url
//         }
//         // console.log(playlistIndex, returnValue);
//         return returnValue
//     }
// }

export default mediaStateReducer;