import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';

//Components
import ButtonComponent from '../../2_components/1_atoms/Button/button.component';

//Helpers
import { getData, setTrackFromPlaylist, calculatePageHeight, openInNewTab } from '../../6_utils/helpers';

//State
import { useMediaState } from '../../3_state/MediaState/MediaState.context';
import MEDIA_STATE_ACTIONS from '../../3_state/MediaState/MediaState.actions';
import { useBaseState } from '../../3_state/BaseState/BaseState.context';
import BASE_STATE_ACTIONS from '../../3_state/BaseState/BaseState.actions';

//Styling
import './project.styles.scss'
import VimeoPlayer from '../../2_components/1_atoms/VimeoPlayer/VimeoPlayer.component';
import YoutubePlayer from '../../2_components/1_atoms/YoutubePlayer/YoutubePlayer.component';

const ProjectPage = (props) => {
    
    const [ projects, setProjects ] = useState([])
    const [project, setProject] = useState({});
    const [ siteLoaded, setSiteLoaded ] = useState(false)
    const [projectMedia, setProjectMedia] = useState([]);
    const [projectLinks, setProjectLinks] = useState([]);
    // const [projectVideos, setProjectVideos] = useState([]);
    const [projectImage, setProjectImage] = useState({});
    const [path, setPath] = useState('');

    const mediaState = useMediaState().mediaState;
    const updateMediaState = useMediaState().dispatch;
    const { playMode, currentTrack, basePlaylist, projectPlaylist, isFullScreen, videoPlaylist} = mediaState;

    const baseState = useBaseState().baseState;
    const updateBaseState = useBaseState().dispatch;
    const { lang, projectCategory, history, contentScrolled } = baseState;


    // const uiHeight = useMemo(() => {
    //     return calculatePageHeight();
    // }, [])

    // const toggleFullScreen = () => {
    //     updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isFullScreen: !isFullScreen}});
    // }

    const playVideo = () => {
        // document.querySelector('video').play();
    }

    useEffect(() => {
        getData([{
            
            // get projects
            url: 'https://backend.serafinaebli.ch/wp-json/wp/v2/project', 
            callback: data =>  {

                setTimeout( () => {
                    document.querySelector('.sm-page').addEventListener('scroll', (e) => {
                        if (e.target.scrollTop >= 1) {
                            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {contentScrolled: true}})
                        } else { //if (e.target.scrollTop < 5){
                            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {contentScrolled: false}})
                        }
                    });
                }, 100);

                let currentProject = data.filter(item => {return item.slug === props.match.params.id})[0]
                let acfFields = currentProject.acf;

                setProjects(data);
                setProject(currentProject);

                // get main project image
                if(acfFields.project_image) {
                    getData([{
                        url: `https://backend.serafinaebli.ch/wp-json/wp/v2/media/${acfFields.project_image.id}`,
                        callback: data => {
                            setProjectImage(data)
                        }
                    }])
                }

                // display video if it exists and no tracks are added

                let projectTrackRequests = []

                let link = '';
                let id = '';
                let type = '';

                if (acfFields.project_video_vimeo) {
                    link = acfFields.project_video_vimeo;
                    id = link.split('vimeo.com/')[1];
                    type = 'vimeo'
                } else if (acfFields.project_video_youtube) {
                    link = acfFields.project_video_youtube;
                    id = link.split('v=')[1];
                    type = 'youtube'
                }
    
                updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: { 
                    videoPlaylist: [{ link, id, type }]
                } })

                
                if (acfFields.project_tracks) {
                    acfFields.project_tracks.forEach(medium => {
                        projectTrackRequests.push({url: `https://backend.serafinaebli.ch/wp-json/wp/v2/media/${medium.project_tracks_item.id}`, callback: () => {}});
                    })
                    
                    getData(projectTrackRequests, returnValues => {
                        setProjectMedia({projectMedia: returnValues});
                        setPlaylist(returnValues, 'audio');
                    });
                }

                setProjectLinks(acfFields.project_links);
                // console.log(projectLinks, acfFields.project_links)
            }
        }], () => {setSiteLoaded(true);})

        updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isProject: true}})

        // TODO: only load once
        if (history) {
            history.listen(location => {
                setPath(history.location.pathname)
                // console.log(path);
            })
        }
        
        return () => {
            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isProject: false, contentScrolled: false}})
            updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: {videoPlaylist: [], projectPlaylist: []}})
            setProjectImage({});
            // console.log('unmounting project');

            // // console.log('thebaseplaylist!!!!!!!', basePlaylist);

            // updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: { 
            //     playMode: 'audio',
            //     isFullScreen: false,
            //     playlist: basePlaylist.filter(track => track.mime_type.includes('audio')),
            //     ...setTrackFromPlaylist({}, basePlaylist.filter(track => track.mime_type.includes('audio')))
            // }});
        }
    }, [path]) 


    // TODO: useContext / useMemo
    useEffect(() => {
        calculatePageHeight();
    }) 

    // useEffect(() => {
    //     // console.log('ey, the mediaState is: ', mediaState.currentTrack, mediaState.nextTrack, mediaState.playlistIndex, mediaState.playlist)
    // }, [mediaState]);

    const getVideoPlayer = () => {
        if (videoPlaylist.length != 0 && videoPlaylist[0].type === 'vimeo') {
            return <VimeoPlayer/>
        } else if (videoPlaylist.length != 0 && videoPlaylist[0].type === 'youtube'){
            return <YoutubePlayer/>
        }
    }
    
    const goTo = (slug, currentPage, callback) => {
        updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { currentPage }})
        baseState.history.push(`${slug}`);
    }

    function setPlaylist (payload, mode) {

        setTimeout(() => {


            if (mode === 'audio') {
                const playlist = payload;
                const newCurrentTrack = playlist[0];
                const newNextTrack = playlist[1] ? playlist[1] : playlist[0];
                const newPrevTrack = playlist[playlist.length - 1];
    
                updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: { 
                    playMode: mode,
                    projectPlaylist: playlist,
                    // currentTrack: {
                    //     name: newCurrentTrack.title.rendered,
                    //     totalTime: newCurrentTrack.media_details.length,
                    //     type: mode,
                    //     url: newCurrentTrack.source_url
                    // },
                    // nextTrack: {
                    //     name: newNextTrack.title.rendered,
                    //     totalTime: newNextTrack.media_details.length,
                    //     type: mode,
                    //     url: newNextTrack.source_url
                    // },
                    // prevTrack: {
                    //     name: newPrevTrack.title.rendered,
                    //     totalTime: newPrevTrack.media_details.length,
                    //     type: mode,
                    //     url: newPrevTrack.source_url
                    // }
                } })
            } 
        }, 100)
    }

    return siteLoaded ? (
        <div className={`sm-page sm-page-project ${contentScrolled ? 'scrolled' : '' }`}>
            <div className="sm-page-project__text copy">{project.acf[`project_text_${lang}`]}</div>
            <div className="sm-page-project__infos">
                { project.acf[`project_info_${lang}`].map((item, i) => {
                    return <div key={'project-info-' + i} className="sm-page-project__infos__info">{item.project_info_title}: {item.project_info_content}</div>    
                })}
            </div>
            <div className={`sm-page-project__medium image-container ${isFullScreen ? 'fullscreen' : ''}`}>
                {projectImage.media_details ? 
                    <img src={projectImage.media_details.sizes.full.source_url} alt={projectImage.alt_text}/>
                :
                    ''
                }
                {getVideoPlayer()}
            </div>

            {projectLinks ? 
                <ul className="sm-page-project__links link-list">
                    {projectLinks.map((item, i) => {
                        return (
                            <ButtonComponent 
                                key={'link-' + i} 
                                classes="sm-button sm-page-project__links__item link-list__item" 
                                title={item.project_links.title} 
                                handleClick={() => {
                                    if (item.project_links.url.includes('http')) {
                                        openInNewTab(item.project_links.url)
                                    } else {
                                        goTo(item.project_links.url, 'news', function () {return; /*updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { currentPage }})*/})
                                        // console.log(item.project_links.url)
                                    }
                                }}        
                            />)
                    })}
                </ul>
            : ''}
            
        </div>
    ) : (
        <div className="sm-loading-indicator"></div>
    )
}

export default ProjectPage

