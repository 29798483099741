import React, { useEffect } from 'react';
import Youtube from 'youtube-player';
import { useMediaState } from '../../../3_state/MediaState/MediaState.context';
import MEDIA_STATE_ACTIONS from '../../../3_state/MediaState/MediaState.actions';

function YoutubePlayer() {

    const mediaState = useMediaState().mediaState;
    const updateMediaState = useMediaState().dispatch;
    const { currentTrack, isPlaying, isFullScreen, volume, videoPlaylist } = mediaState;

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };


    useEffect(() => {
        let youtubePlayer = document.querySelector('iframe');

        // youtubePlayer.setColor('#ffff00');

        if (youtubePlayer) {
            console.log(youtubePlayer)
            youtubePlayer.addEventListener('play', () => {
                console.log('playing')
                // updateMediaState({type: MEDIA_STATE_ACTIONS.TOGGLE_PLAY, payload: { isPlaying: false}});
            })
        }

        // youtubePlayer.on('pause', () => {
        //     console.log('pausing')
        //     updateMediaState({type: MEDIA_STATE_ACTIONS.TOGGLE_PLAY, payload: { isPlaying: true}});
        // })

        return ( () => {
            updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: { videoPlaylist: []}});
            // console.log('dismounting youtube');
        })
    }, [])

    useEffect(() => {
        // if (youtubePlayer) youtubePlayer.setVolume(volume);
    }, [mediaState])

    // useEffect(() => {
    //     youtubePlayer.play();

    //     // if (isPlaying) {
    //     //     youtubePlayer.play();
    //     // } else {
    //     //     youtubePlayer.pause();
    //     // }

    // }, [isPlaying])

    const toggleFullScreen = () => {
        updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: { isPlaying: !isPlaying, isFullScreen: !isFullScreen}});
    }

    return (
        <div className="iframe-container">
            <iframe width="640" height="480" src={`https://www.youtube.com/embed/${videoPlaylist[0].id}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            {/* <Youtube videoId="2g811Eo7K8U" opts={opts} /> */}
        </div>
    )
}

export default YoutubePlayer
