import React, { useState, useEffect } from 'react'
import axios from 'axios';

//Components
import ButtonComponent from '../../2_components/1_atoms/Button/button.component';

//Helpers
import { calculatePageHeight, getData, openInNewTab } from '../../6_utils/helpers';

//Styling
import './news.styles.scss';

//State
import { useBaseState } from '../../3_state/BaseState/BaseState.context';
import BASE_STATE_ACTIONS from '../../3_state/BaseState/BaseState.actions';

const NewsPage = () => {

    // state = {
    //     news: [],
    //     contentLoaded: false
    // }

    const [ news, setNews ] = useState([])
    const [ siteLoaded, setSiteLoaded ] = useState(false)

    const baseState = useBaseState().baseState;
    const updateBaseState = useBaseState().dispatch;
    const { lang } = baseState;

    useEffect(() => {
        getData([{
            url: 'https://backend.serafinaebli.ch/wp-json/wp/v2/news', 
            callback: data =>  setNews(data)
        }], () => {setSiteLoaded(true)})
    }, []) 

    useEffect(() => {
        calculatePageHeight();
    })

    const goTo = (slug, currentPage) => {
        updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { currentPage }})
        baseState.history.push(`${slug}`);
    }

    return siteLoaded ? (
        <div className="sm-page sm-page-news">
            <ul className="sm-page-news__list">
                { news.map((item, i) => {
                    return (<li key={'news-' + i} className="sm-page-news__list__item">
                        <div className="sm-page-news__list__item__date">{item.acf.news_date}</div>
                        <div className="sm-page-news__list__item__text copy">{item.acf[`news_text_${lang}`]}</div>
                        <ul className="sm-page-news__list__item__links link-list">
                            {item.acf.news_links && item.acf.news_links.map((item, i) => {
                                return (
                                    <ButtonComponent 
                                        key={'link-' + i} 
                                        classes="sm-button sm-page-news__list__item__links__item link-list__item" 
                                        title={item.news_links_link.title} 
                                        handleClick={() => {
                                            if (item.news_links_link.url.includes('http')) {
                                                openInNewTab(item.news_links_link.url)
                                            } else {
                                                goTo(item.news_links_link.url, 'news')
                                                console.log(item.news_links_link.url)
                                            }
                                        }}        
                                    ></ButtonComponent>)
                            })}
                        </ul>
                    </li>)
                })}
            </ul>
        </div>
    ) : (
        <div className="sm-loading-indicator"></div>
    )
}

export default NewsPage

