import React, { useContext, useReducer, useMemo } from 'react';
import MediaState from './MediaState';
import mediaStateReducer from './MediaState.reducer';

const MediaStateContext = React.createContext();

export const useMediaState = () => {
    return useContext(MediaStateContext);
}


export const MediaStateProvider = ({ children }) => {

    const [mediaState, dispatch] = useReducer(mediaStateReducer, MediaState);

    const contextValue = useMemo(() => {
        return {mediaState, dispatch}
    }, [mediaState, dispatch])

    return (
        <MediaStateContext.Provider value={contextValue}>
            { children }    
        </MediaStateContext.Provider>
    )
}