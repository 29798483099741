import React from 'react'

export default function ButtonComponent(props) {
    const {title, classes, type, handleClick, children } = props;
    return (
        <button className={`${classes} sm-button sm-button-${type}`} onClick={handleClick}>
            {children ? children : ''}
            {title}
        </button>
    )
}
