import React from 'react';

import './loading-screen.styles.scss';

const LoadingScreenComponent = () => {
    return (
        <div className="sm-loading">
            <div className="sm-loading__bar">
                <div className="sm-loading__bar__progress"></div>
            </div>
        </div>
    )
}

export default LoadingScreenComponent