import React, { useEffect } from 'react';
import Player from '@vimeo/player';
import { useMediaState } from '../../../3_state/MediaState/MediaState.context';
import MEDIA_STATE_ACTIONS from '../../../3_state/MediaState/MediaState.actions';

function VimeoPlayer() {

    const mediaState = useMediaState().mediaState;
    const updateMediaState = useMediaState().dispatch;

    const { currentTrack, isPlaying, isFullScreen, volume, videoPlaylist } = mediaState;

    let vimeoPlayer = null;

    useEffect(() => {

        var iframe = document.querySelector('iframe');

        if (iframe) {
            vimeoPlayer = new Player(iframe);

            vimeoPlayer.setColor('#ffff00');

            vimeoPlayer.on('play', () => {
                // updateMediaState({type: MEDIA_STATE_ACTIONS.TOGGLE_PLAY, payload: { isPlaying: false}});
                console.log('playing', mediaState)
            })

            vimeoPlayer.on('pause', () => {
                // updateMediaState({type: MEDIA_STATE_ACTIONS.TOGGLE_PLAY, payload: { isPlaying: true}});
                console.log('pausing', mediaState)
            })
        }

        return ( () => {
            updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: { videoPlaylist: []}});
            // console.log('dismounting vimeo');
        })
    }, [])

    useEffect(() => {
        if (vimeoPlayer) vimeoPlayer.setVolume(volume);
    }, [mediaState])

    // useEffect(() => {
    //     vimeoPlayer.play();

    //     // if (isPlaying) {
    //     //     vimeoPlayer.play();
    //     // } else {
    //     //     vimeoPlayer.pause();
    //     // }

    // }, [isPlaying])

    const toggleFullScreen = () => {
        updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: { isPlaying: !isPlaying, isFullScreen: !isFullScreen}});
    }

    return (
        <div className="iframe-container">
            <iframe src={`https://player.vimeo.com/video/${videoPlaylist[0].id}`} width="640" height="480" frameBorder="0" allowFullScreen controls="0"></iframe>
        </div>
    )
}

export default VimeoPlayer
