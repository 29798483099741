import React, { useContext, useReducer, useMemo } from 'react';
import BaseState from './BaseState';
import baseStateReducer from './BaseState.reducer';

const BaseStateContext = React.createContext();
const BaseStateContextUpdate = React.createContext();

export const useBaseState = () => {
    return useContext(BaseStateContext);
}

export const BaseStateProvider = ({ children }) => {

    const [baseState, dispatch] = useReducer(baseStateReducer, BaseState);
    const contextValue = useMemo(() => {
        return {baseState, dispatch}
    }, [baseState, dispatch])

    return (
        <BaseStateContext.Provider value={contextValue}>
            { children }    
        </BaseStateContext.Provider>
    )
}