import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { getMedium, getData, calculatePageHeight } from '../../6_utils/helpers';
import { useBaseState } from '../../3_state/BaseState/BaseState.context';
import BASE_STATE_ACTIONS from '../../3_state/BaseState/BaseState.actions';

//Styling
import './about.styles.scss';

const AboutPage = (props) => {
    
    const [projectImage, setProjectImage] = useState({});
    const [about, setAbout] = useState({});
    const [siteLoaded, setSiteLoaded] = useState(false);

    const baseState = useBaseState().baseState;
    const { lang } = baseState;

    useEffect(() => {
        getData([{
            
            // get projects
            url: 'https://backend.serafinaebli.ch/wp-json/wp/v2/pages', 
            callback: data =>  {
                setAbout(data.filter(item => item.slug === 'about')[0].acf);
            }
        }], () => {setSiteLoaded(true);})

        // updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isProject: true}})
        
        return () => {
            // updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isProject: false}})
        }
    }, []) 

    useEffect(() => {
        calculatePageHeight();
    }) 

    // useEffect(() => {
    //     console.log('ey, the mediaState is: ', mediaState.currentTrack, mediaState.nextTrack, mediaState.playlistIndex, mediaState.playlist)
    // }, [mediaState]);


    return siteLoaded ? (
        <div className="sm-page sm-page-about">
            <div className="sm-page-page-about__lead copy">{about[`about_lead_${lang}`]}</div>
            <div className="image-container">
                <img src={about.about_image}/>
            </div>   
            <div className="sm-page-page-about__text copy">{about[`about_text_${lang}`]}</div>   
        </div>
    ) : (
        <div className="sm-loading-indicator"></div>
    )
}

export default AboutPage

