const BaseState = {
    lang: 'de',
    pageTitle: '',
    pages: [],
    projects: [],
    projectCategory: '',
    news: [],
    contentLoaded: false,
    isProject: false,
    currentPage: '',
    screenSize: 'large',
    contentScrolled: false
}

export default BaseState;