import React, { useState, useRef, useEffect } from 'react'

//Components
import ButtonComponent from '../../1_atoms/Button/button.component';

//State

import { useMediaState } from '../../../3_state/MediaState/MediaState.context';
import MEDIA_STATE_ACTIONS from '../../../3_state/MediaState/MediaState.actions';
import { useBaseState, useUpdateBaseState } from '../../../3_state/BaseState/BaseState.context';
import BASE_STATE_ACTIONS from '../../../3_state/BaseState/BaseState.actions';

//Styling
import './player.styles.scss';
import { ReactComponent as PlayIcon } from '../../../5_assests/img/icon_play.svg';
import { ReactComponent as PauseIcon } from '../../../5_assests/img/icon_pause.svg';
import { ReactComponent as PlusIcon } from '../../../5_assests/img/icon_plus.svg';
import { ReactComponent as MinusIcon } from '../../../5_assests/img/icon_minus.svg';
import { ReactComponent as PrevIcon } from '../../../5_assests/img/icon_prev.svg';
import { ReactComponent as NextIcon } from '../../../5_assests/img/icon_next.svg';

//Helpers
import { getData, setTrackFromPlaylist } from '../../../6_utils/helpers';

const PlayerComponent = (props) => {


    const [progress, setProgress] = useState(0);
    const goTo = props.goTo;

    const baseState = useBaseState().baseState;
    const updateBaseState = useBaseState().dispatch;
    const { lang, screenSize, device } = baseState;

    const mediaState = useMediaState().mediaState;
    const updateMediaState = useMediaState().dispatch;
    const { currentTrack, isPlaying, volume, playedTime, playMode } = mediaState;

    const audioPlayer = useRef(null);
    const [isFirstPlay, setIsFirstPlay] = useState(true);

    useEffect(() => {
        getData([
            { 
                url: 'https://backend.serafinaebli.ch/wp-json/wp/v2/media',
                callback: (data) => {
                    const filteredData = data.filter(track => track.mime_type.includes('audio'));
                    console.log(data);
                    updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: { 
                        playlist: filteredData,
                        basePlaylist: filteredData,
                        ...setTrackFromPlaylist({}, filteredData)
                    }});
                    // console.log(setTrackFromPlaylist({}, data));
                }
            }
        ])

        // if (device.device.type == "desktop") {
        //     console.log('desktop');
        //     // updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {device}})
        // } 

    }, [])

    useEffect(() => {
        //TODO: check why this returns an error
        //TODO: make it dependent of isPlaying again
        if (isPlaying) {
            if (audioPlayer.current) audioPlayer.current.play();
        } else {
            if (audioPlayer.current) audioPlayer.current.pause();
        }
    })

    useEffect(() => {
        if(playMode === 'video') {
            if (audioPlayer.current) audioPlayer.current.pause();
        }

    }, [playMode])

    useEffect(() => {
        if (audioPlayer.current) audioPlayer.current.volume = volume
    }, [volume])


    useEffect(() => {
        if (audioPlayer.current) audioPlayer.current.addEventListener('timeupdate', updateTime);
        
        return () => {
            if (audioPlayer.current) audioPlayer.current.removeEventListener('timeupdate', updateTime);
        }

    }, [mediaState])

    function updateTime () {
        let playedTime = audioPlayer.current ? audioPlayer.current.currentTime : 0;
        let progress = updateProgress(playedTime);
        updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: {progress, playedTime}})
    }

    const playPrevious = () => {
        updateMediaState({type: MEDIA_STATE_ACTIONS.PLAY_PREV})
    }

    const playNext = () => {        
        updateMediaState({type: MEDIA_STATE_ACTIONS.PLAY_NEXT})
    }

    const togglePlay = () => {
        if (isFirstPlay) {
            setIsFirstPlay(false);
            console.error('first', currentTrack)
            updateMediaState({type: MEDIA_STATE_ACTIONS.SET_TRACK, payload: {name: currentTrack.title}})
        } else {
            updateMediaState({type: MEDIA_STATE_ACTIONS.TOGGLE_PLAY})
        }
    }

    const turnVolumeUp = () => {
        updateMediaState({type: MEDIA_STATE_ACTIONS.CHANGE_VOLUME, payload: 'up'})
    }

    const turnVolumeDown = () => {
        updateMediaState({type: MEDIA_STATE_ACTIONS.CHANGE_VOLUME, payload: 'down'})
    }

    const toggleLanguage = () => {
        updateBaseState({type: BASE_STATE_ACTIONS.TOGGLE_LANGUAGE})
    }

    const formatTime  = (time) => {
        time = Math.floor(time);
        let minutes = Math.floor(time / 60) < 10 ? '0' + Math.floor(time / 60) : Math.floor(time / 60);
        let seconds = time % 60 < 10 ? '0' + time % 60 : time % 60;
        
        return minutes + ':' + seconds
    }

    const updateProgress = (playedTime) => {
        let percent = Math.floor(playedTime * (100 / currentTrack.totalTime));
        setProgress(percent)
        return percent;
    }


    return (
        <div className="sm-player">
            { screenSize === 'large' ? <ButtonComponent classes="sm-player__item sm-player__item-home" title={baseState.pageTitle} handleClick={() => {goTo("")}}/> : ''}
            { playMode === 'audio' ? <ButtonComponent classes="sm-player__item sm-player__item-prev" title="" handleClick={playPrevious}><PrevIcon/></ButtonComponent> : ''}
            <ButtonComponent classes="sm-player__item sm-player__item-play" title="" handleClick={togglePlay}>{isPlaying ? <PauseIcon/> : <PlayIcon/>}</ButtonComponent>
            { playMode === 'audio' ? <ButtonComponent classes="sm-player__item sm-player__item-next" title="" handleClick={playNext}><NextIcon/></ButtonComponent> : ''}
            <ButtonComponent classes="sm-player__item sm-player__item-down" title="" handleClick={turnVolumeDown}><MinusIcon/></ButtonComponent>
            <ButtonComponent classes="sm-player__item sm-player__item-up" title="" handleClick={turnVolumeUp}><PlusIcon/></ButtonComponent>
            <div className="sm-player__item sm-button sm-player__item-name sm-button-no-interaction">{currentTrack.name}</div>
            <div className="sm-player__item sm-button sm-player__item-time sm-button-no-interaction">{formatTime(playedTime)}</div>
            <div className="sm-player__item sm-player__item-progress sm-button sm-button-no-interaction">
                {/* {progress}% */}
                <div style={{transform: `scaleX(${progress / 100})`}} className="sm-player__item-progress__bar"></div>
            </div>
            <div className="sm-player__item sm-button sm-player__item-time sm-button-no-interaction">{formatTime(currentTrack.totalTime)}</div>
            <ButtonComponent classes="sm-player__item sm-player__item-lang" title={lang === 'en' ? 'D' : 'E'} handleClick={toggleLanguage}/>
            { playMode === 'audio' ?
                <audio  id="audioPlayer" ref={audioPlayer} controls loop src={currentTrack.url}></audio>
            :
                ''
            }
        </div>
    )
}

export default PlayerComponent