import React, { useState, useEffect, useMemo, useRef } from 'react'
import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';

//Components
import ButtonComponent from '../../1_atoms/Button/button.component';
import PlayerComponent from '../player/player.component';

//Helpers
import { getData, getUserDevice } from '../../../6_utils/helpers';
import DeviceDetector from 'device-detector-js';
//State
import { useBaseState } from '../../../3_state/BaseState/BaseState.context';
import BASE_STATE_ACTIONS from '../../../3_state/BaseState/BaseState.actions';

//Styling
import './header.styles.scss';
import { useMediaState } from '../../../3_state/MediaState/MediaState.context';
import MEDIA_STATE_ACTIONS from '../../../3_state/MediaState/MediaState.actions';
import { ReactComponent as TrackIcon } from '../../../5_assests/img/icon_track.svg';
import { ReactComponent as TrackInactiveIcon } from '../../../5_assests/img/icon_track-inactive.svg';


export const HeaderComponent = (props) => {

    const [pages, setPages] = useState([]);
    const [projects, setProjects] = useState([]);
    const [pageTitle, setPageTitle] = useState('');
    const [headerLoaded, setheaderLoaded] = useState(false);
    // const [currentPage, setCurrentPage] = useState('');
    const history = useHistory();
    let url = history.location.pathname;
    
    const baseState = useBaseState().baseState;
    const updateBaseState = useBaseState().dispatch;
    
    const mediaState = useMediaState().mediaState;
    const updateMediaState = useMediaState().dispatch;
    
    const { projectCategory, lang, screenSize, contentScrolled } = baseState;
    const { playlist, projectPlaylist, videoPlaylist, currentTrack, isFullScreen, isPlaying } = mediaState;

    const categoryList = useRef(null);
    const projectList = useRef(null);

    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);

    useEffect(() => {

        checkScreenSize();

        updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {device}})
        // console.log(device);

        updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { history }})

        getData([
            { 
                url: 'https://backend.serafinaebli.ch/wp-json/',
                callback: (data) => updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { pageTitle: data.name }})
            },
            { 
                url: 'https://backend.serafinaebli.ch/wp-json/wp/v2/pages',
                callback: (data) => setPages(data)
            },
            { 
                url: 'https://backend.serafinaebli.ch/wp-json/wp/v2/project',
                callback: (data) => {
                    setProjects(data); 
                    updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { projects: data }})
                }
            }
        ], () => {setheaderLoaded(true)});

        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        }

    }, [])

    useEffect(() => {
        url = history.location.pathname;
        if (url.includes('compositions')) {
            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { projectCategory: 'compositions' }})
        } else if (url.includes('soundtracks')) {
            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { projectCategory: 'soundtracks' }})
        } else {
            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { projectCategory: '' }})
        }

    }, [url])

    const goTo = (slug, currentPage) => {
        // console.log(slug)
        // setCurrentPage(slug);
        updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { currentPage }})
        // console.log(history)
        baseState.history.push(`/${slug}`);
    }

    const checkScreenSize = () => {
        const device = getUserDevice();

        if (device.screenSize.width > 768) {
            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { screenSize: 'large' }})
        } else {
            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { screenSize: 'small' }})
        }
    }

    // const collapseItem = (item, toOpen = true) => {

    //     item = item.current ? item.current : item;
    //     const itemHeight = item.scrollHeight;
    //     // console.log(itemHeight, item.style.height)

    //     if (toOpen) {
    //         item.style.height = itemHeight;
    //     } else {
    //         item.style.height = '5px';
    //     }
    // }

    return (
        <div className={`sm-header ${isFullScreen ? 'fullscreen' : ''}`}>
            { screenSize === 'small' ? <ButtonComponent classes="sm-header__item sm-header__item-home" title={baseState.pageTitle} handleClick={() => {goTo("")}}/> : ''}
            {/* { screenSize === 'large' ? <PlayerComponent pageTitle={pageTitle} goTo={goTo}/> : ''} */}
            <PlayerComponent pageTitle={pageTitle} goTo={goTo}/>
            { !isFullScreen ? 
                <nav className="sm-header__menu">
                    <ul className="sm-header__menu__list sm-header__menu__list-main">
                    { headerLoaded ? 
                            pages.filter(item => item.slug !== 'home').map((page, i) => {
                                return (
                                    <ButtonComponent 
                                        key={'page-'+i}
                                        classes={`sm-button ${url.includes(page.slug) ? 'active' : url.includes(page.slug) || url === "/" ? '' : 'inactive'} `} 
                                        // title={page.title.rendered} 
                                        title={page.acf[`page_title_${lang}`]} 
                                        handleClick={() => {
                                            goTo(page.slug, 'category'); 
                                            updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {projectCategory: 'compositions', contentScrolled: false}});
                                        }}
                                    />
                                )
                            })
                        : <div className="sm-loading-indicator"></div>
                    }
                    </ul>
                    <ul className={`sm-header__menu__list sm-header__menu__list-categories ${!url.includes('projekte') ? 'hidden' : ''}`} 
                        ref={categoryList}>
                        <ButtonComponent 
                            classes={baseState.projectCategory && baseState.projectCategory !== 'compositions' ? 'inactive' : 'active'} 
                            title={'compositions'} 
                            handleClick={() => {
                                goTo('projekte/compositions', 'category'); 
                                updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {projectCategory: 'compositions', contentScrolled: false}});
                            }}
                        />
                        <ButtonComponent 
                            classes={baseState.projectCategory && baseState.projectCategory !== 'soundtracks' ? 'inactive' : 'active'} 
                            title={'soundtracks'} 
                            handleClick={() => {
                                goTo('projekte/soundtracks', 'category'); 
                                updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {projectCategory: 'soundtracks', contentScrolled: false}});
                            }}
                        />
                    </ul>
                    <ul className={`sm-header__menu__list sm-header__menu__list-projects sm-header__menu__list-projects-compositions ${!url.includes('compositions') ? 'hidden' : ''}`}
                        ref={projectList}>
                        { headerLoaded ? 
                            projects.map((project, i) => {
                                if(project.project_categories[0] === 2) {
                                    return (
                                        <ButtonComponent key={'comp-proj-'+i} classes={`${(!url.includes(project.slug) && baseState.isProject) ? 'inactive' : 'active'} ${!url.includes(project.slug) && contentScrolled ? 'collapsed' : '' }`}  title={project.title.rendered} handleClick={() => {goTo(`projekte/${projectCategory}/${project.slug}`, 'project'); updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {contentScrolled: true}});}} />
                                    )
                                }
                            })
                            : <div className="sm-loading-indicator"></div>
                        }
                    </ul>
                    <ul className={`sm-header__menu__list sm-header__menu__list-projects sm-header__menu__list-projects-soundtracks ${!url.includes('soundtracks') ? 'hidden' : ''}`}>
                    { headerLoaded ? 
                            projects.map((project, i) => {
                                if(project.project_categories[0] === 3) {
                                    return (
                                        <ButtonComponent key={'sounttr-proj'+i} classes={`${(!url.includes(project.slug) && baseState.isProject) ? 'inactive' : 'active'} ${!url.includes(project.slug) && contentScrolled ? 'collapsed' : '' }`}  title={project.title.rendered} handleClick={e => {goTo(`projekte/${projectCategory}/${project.slug}`, 'project'); updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {contentScrolled: true}});}} />
                                    )
                                }
                            })
                            : <div className="sm-loading-indicator"></div>
                        }
                    </ul>
                    <ul className={`sm-header__menu__list sm-header__menu__list-tracks ${!url.includes('soundtracks/') && !url.includes('compositions/') ? 'hidden' : ''}`}>
                    { headerLoaded ? 
                        (projectPlaylist.length == 0) ?
                            ''
                        :
                            projectPlaylist.length > 0 ? 
                                projectPlaylist.map((track, i) => {
                                    return (
                                        <ButtonComponent 
                                            key={'track-'+i} 
                                            classes={`${baseState.isProject && ((currentTrack.name !== track.title.rendered && isPlaying)) ? 'inactive' : 'active'} sm-header__menu__list-tracks__item sm-track`}  
                                            title={track.title.rendered} 
                                            handleClick={() => {
                                                
                                                if(projectPlaylist != playlist) {
                                                    // console.log(playlist, projectPlaylist)
                                                    updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: {playlist: projectPlaylist}})

                                                }
                                                // console.log('set track')
                                                updateMediaState({type: MEDIA_STATE_ACTIONS.SET_TRACK, payload: {name: track.title.rendered}})
                                            }}
                                        >
                                            {(currentTrack.name !== track.title.rendered && baseState.isProject) ? <TrackInactiveIcon/> : <TrackIcon/>}
                                        </ButtonComponent>
                                    )
                                })
                            : 
                                playlist.map((track, i) => {
                                    return (
                                        <ButtonComponent 
                                            key={'track-'+i} 
                                            classes={`${(currentTrack.name !== track.title.rendered && baseState.isProject && isPlaying) ? 'inactive' : ''} sm-header__menu__list-tracks__item sm-track`}  
                                            title={track.title.rendered} 
                                            handleClick={() => {
                                                updateMediaState({type: MEDIA_STATE_ACTIONS.SET_TRACK, payload: {name: track.title.rendered}})
                                            }}
                                        >
                                            {(currentTrack.name !== track.title.rendered && baseState.isProject) ? <TrackInactiveIcon/> : <TrackIcon/>}
                                        </ButtonComponent>
                                    )
                                })
                    : <div className="sm-loading-indicator"></div>
                    }
                    </ul>
                    {/* { playMode === 'audio' ?
                        <ul className={`sm-header__menu__list sm-header__menu__list-tracks ${!url.includes('soundtracks/') && !url.includes('compositions/') ? 'hidden' : ''}`}>
                        { headerLoaded ? 
                                (videoPlaylist.length != 0) ?
                                    videoPlaylist.map((track, i) => {
                                        return (
                                            <ButtonComponent key={'track-'+i} classes={`sm-header__menu__list-tracks__item sm-track`}  title="Trailer" handleClick={() => {updateMediaState({type: MEDIA_STATE_ACTIONS.SET_TRACK, payload: {name: track.title.rendered}})}}>{(currentTrack.name !== track.title.rendered && baseState.isProject) ? <TrackInactiveIcon/> : <TrackIcon/>}</ButtonComponent>
                                        )
                                    })
                                :
                                    playlist.map((track, i) => {
                                        return (
                                            <ButtonComponent key={'track-'+i} classes={`${(currentTrack.name !== track.title.rendered && baseState.isProject) ? 'inactive' : ''} sm-header__menu__list-tracks__item sm-track`}  title={track.title.rendered} handleClick={() => {updateMediaState({type: MEDIA_STATE_ACTIONS.SET_TRACK, payload: {name: track.title.rendered}})}}>{(currentTrack.name !== track.title.rendered && baseState.isProject) ? <TrackInactiveIcon/> : <TrackIcon/>}</ButtonComponent>
                                        )
                                    })
                                : <div className="sm-loading-indicator"></div>
                            }
                        </ul>
                    :
                    <ul className={`sm-header__menu__list sm-header__menu__list-tracks ${!url.includes('soundtracks/') && !url.includes('compositions/') ? 'hidden' : ''}`}>
                        { headerLoaded ? 
                                <ButtonComponent classes={`sm-header__menu__list-tracks__item sm-track`}  title={currentTrack.name} handleClick={() => {updateMediaState({type: MEDIA_STATE_ACTIONS.SET_MEDIA_STATE, payload: {isPlaying: true}})}}><TrackIcon/></ButtonComponent>
                                : <div className="sm-loading-indicator"></div>
                            }
                        </ul>
                    } */}
                </nav>
            :
                ''
            }
        </div>
    )
}

export default HeaderComponent
