const MediaState = {
    isPlaying: false,
    totalTime: 0,
    playedTime: 0,
    volume: 0.5,
    currentTrack: {
        name: '',
        type: '',
        url: '',
        totalTime: 0
    },
    nextTrack: {
        name: '',
        type: '',
        url: '',
        totalTime: 0
    },
    prevTrack: {
        name: '',
        type: '',
        url: '',
        totalTime: 0
    }, 
    playlist: [],
    basePlaylist: [],
    projectPlaylist: [],
    videoPlaylist: [],
    playlistIndex: 0,
    playMode: 'audio',
    isFullScreen: false
};

export default MediaState;