import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { getMedium, getData, calculatePageHeight } from '../../6_utils/helpers';
import { useBaseState } from '../../3_state/BaseState/BaseState.context';
import BASE_STATE_ACTIONS from '../../3_state/BaseState/BaseState.actions';

//Styling
import './home.styles.scss';

const HomePage = (props) => {
    
    const [projectImage, setProjectImage] = useState({});
    const [landingImages, setlandingImages] = useState([]);
    const [siteLoaded, setSiteLoaded] = useState(false);
    const [randomImage, setRandomImage] = useState('');
    const [isPortrait, setIsPortrait] = useState(false);

    const baseState = useBaseState().baseState;
    const { lang } = baseState;

    useEffect(() => {
        getData([{
            
            // get projects
            url: 'https://backend.serafinaebli.ch/wp-json/wp/v2/pages', 
            callback: data =>  {
                let acfFields = data.filter(item => item.slug === 'home')[0].acf;
                // console.log(data.filter(item => item.slug === 'home')[0].acf)

                if(acfFields.landing_images) {
                    let landingImageArray = []

                    acfFields.landing_images.forEach(image => {
                        landingImageArray.push({url: image.url, isPortrait: image.height > image.width})
                    });

                    //TODO: Aw, Snap error first appeared

                    setlandingImages(landingImageArray);
                    let randomInt = Math.floor(Math.random() * (landingImageArray.length) );
                    // console.log(data);
                    setRandomImage(landingImageArray[randomInt].url)
                    setIsPortrait(landingImageArray[randomInt].isPortrait ? 'portrait' : 'landscape')
                }
            }
        }], () => {setSiteLoaded(true);})

        // document.querySelector('.sm-page-home__image').style.opacity = 1;

        // updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isProject: true}})
        
        return () => {
            // document.querySelector('.sm-page-home__image').style.opacity = 0;
            // updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isProject: false}})
        }

    }, []) 

    useEffect(() => {
        calculatePageHeight();
    }) 

    // useEffect(() => {
    //     console.log('ey, the mediaState is: ', mediaState.currentTrack, mediaState.nextTrack, mediaState.playlistIndex, mediaState.playlist)
    // }, [mediaState]);


    return siteLoaded ? (
        <div className="sm-page sm-page-home">
            <div className={`sm-page-home__image sm-page-home__image-${isPortrait}`}>
                <img src={randomImage}/>
            </div> 
        </div>
    ) : (
        <div className="sm-loading-indicator"></div>
    )
}

export default HomePage

