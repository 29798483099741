import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { getMedium, getData, openInNewTab } from '../../6_utils/helpers';
import { useBaseState } from '../../3_state/BaseState/BaseState.context';
import BASE_STATE_ACTIONS from '../../3_state/BaseState/BaseState.actions';

//Styling 
import './contact.styles.scss';
import ButtonComponent from '../../2_components/1_atoms/Button/button.component';

const ContactPage = (props) => {
    
    const [projectImage, setProjectImage] = useState({});
    const [contact, setContact] = useState({});
    const [siteLoaded, setSiteLoaded] = useState(false);
    const [contactLinks, setContactLinks] = useState([]);

    const baseState = useBaseState().baseState;
    const updateBaseState = useBaseState().dispatch;
    const { lang } = baseState;

    useEffect(() => {
        getData([{
            
            // get projects
            url: 'https://backend.serafinaebli.ch/wp-json/wp/v2/pages', 
            callback: data =>  {
                setContact(data.filter(item => item.slug === 'kontakt')[0]);
            }
        }], () => {setSiteLoaded(true);})

        // updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isProject: true}})
        
        return () => {
            // updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: {isProject: false}})
        }
    }, []) 

    useEffect(() => {
        setContactLinks(contact?.acf?.contact_links);
    }, [contact]) 
    
    const goTo = (slug, currentPage, callback) => {
        updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { currentPage }})
        baseState.history.push(`${slug}`);
    }

    // useEffect(() => {
    //     console.log('ey, the mediaState is: ', mediaState.currentTrack, mediaState.nextTrack, mediaState.playlistIndex, mediaState.playlist)
    // }, [mediaState]);


    return siteLoaded ? (
        <div className="sm-page sm-page-contact">
            <div className="sm-page-contact__text copy" dangerouslySetInnerHTML={{__html: contact.acf[`contact_${lang}`]}}></div>    
            {contactLinks ? 
                <ul className="sm-page-contact_links link-list">
                    {contactLinks.map((item, i) => {

                        if (item.contact_links_link.url.includes('mailto')) {
                            return <a className="sm-button" href={item.contact_links_link.url}>{item.contact_links_link.title}</a>
                        } else {

                            return (
                                <ButtonComponent 
                                    key={'link-' + i} 
                                    classes="sm-button sm-page-contact_links__item link-list__item" 
                                    title={item.contact_links_link.title} 
                                    handleClick={() => {
                                        if (item.contact_links_link.url.includes('http')) {
                                            openInNewTab(item.contact_links_link.url)
                                        } else {
                                            goTo(item.contact_links_link.url, 'news', function () {return; /*updateBaseState({type: BASE_STATE_ACTIONS.SET_BASE_STATE, payload: { currentPage }})*/})
                                            // console.log(item.contact_links.url)
                                        }
                                    }}        
                                />
                            )
                        }
                    })}
                </ul>
            : ''}
        </div>
    ) : (
        <div className="sm-loading-indicator"></div>
    )
}

export default ContactPage

