import axios from 'axios';
import { useEffect, useRef } from 'react';

export const getData = async (requests, callback = () => {}) => {

    let promises = [];
    let returnValues = []

    // create axios request for each incomming request
    requests.forEach(request => {
        promises.push( axios.get(request.url));
    });


    Promise.all(promises)
        .then(responses => {
            for (let i = 0; i < responses.length; i ++) {
                requests[i].callback(responses[i].data, [])
                returnValues.push(responses[i].data)
            }
            callback(returnValues);
        })

} 

export const openInNewTab = (url) => {
    var win = window.open(url, '_blank');
    win.focus();
}

export const getMedium = async (id) => {


    getData([{url: `https://backend.serafinaebli.ch/wp-json/wp/v2/media/${id}`, callback: (data) => console.log(data)}])
        // .then(res => console.log(res));
    // axios.get(`/wp-json/wp/v2/media/${id}`)
    //     .then(res => {
    //         medium.title = res.data.title;
    //         medium.altText = res.data.alt_text;
    //         medium.mediaType = res.data.media_type;
    //         medium.urls = {
    //             medium: res.data.media_details.sizes.medium.source_url,
    //             large: res.data.media_details.sizes.large.source_url,
    //             thumbnail: res.data.media_details.sizes.thumbnail.source_url,
    //             mediumLarge: res.data.media_details.sizes.medium_large.source_url,
    //             full: res.data.media_details.sizes.full.source_url    
    //         }
    //         console.log('the medium is: ', medium)
    //         return medium;
    //     })
    //     .catch(err => console.log(err))
}

export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
            ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});

        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

export const setTrackFromPlaylist = (nextTrack = {name: '', url: ''}, playlist, currentTrack = {}) => {

    // Returns a current, previous and next from a playlist, either by name, by url or at random

    let nextTrackIndex = 0;
    let currentTrackIndex = 0;

    // Search Playlist for track or choose a random one
    if (nextTrack.name) {
        nextTrackIndex = playlist.findIndex(item => item.title.rendered === nextTrack.name)
    } else if (nextTrack.url) {
        nextTrackIndex = playlist.findIndex(item => item.source_url === nextTrack.url)
    } else {
        nextTrackIndex = Math.floor(Math.random() * (playlist.length - 1));
    }

    // Set the current track as previous track if set or to track before the next track if unset
    if (currentTrack.name) {
        currentTrackIndex = playlist.findIndex(item => item.title.rendered === currentTrack.name)
    } else if (currentTrack.url) {
        currentTrackIndex = playlist.findIndex(item => item.source_url === currentTrack.url)
    } else {
        currentTrackIndex = playlist[nextTrackIndex - 1] ? 
            playlist.findIndex(item => playlist[nextTrackIndex - 1])
        : 
            playlist.findIndex(item => playlist[playlist.length - 1]);
        // playlist.findIndex(item => item.source_url === currentTrack.url)
    }

    let returnCurrentTrack = playlist[nextTrackIndex];
    let returnNextTrack = playlist[nextTrackIndex + 1] ? playlist[nextTrackIndex + 1] : playlist[0];
    let returnPrevTrack = playlist[currentTrackIndex];

    let returnValue = {
        currentTrackIndex,
        currentTrack: {
            name: returnCurrentTrack.title.rendered,
            type: 'audio',
            url: returnCurrentTrack.source_url,
            totalTime: returnCurrentTrack.media_details.length
        },
        nextTrack: {
            name: returnNextTrack.title.rendered,
            type: 'audio',
            url: returnNextTrack.source_url,
            totalTime: returnNextTrack.media_details.length
        },
        prevTrack: {
            name: returnPrevTrack.title.rendered,
            type: 'audio',
            url: returnPrevTrack.source_url,
            totalTime: returnPrevTrack.media_details.length
        } 
    }

    return returnValue
}

export const getUserDevice = () => {
    return {
        screenSize: {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
}

export const calculatePageHeight = () => {
    const player = document.querySelector('.sm-player');
    const header = document.querySelector('.sm-header');
    const page = document.querySelector('.sm-page');

    if (player && header && page) {
        // console.log(player.offsetHeight, header.offsetHeight);
        page.style.height = `calc(100vh - ${player.offsetHeight}px - ${header.offsetHeight}px`;

        return player.offsetHeight + header.offsetHeight;
    }
}