import React, { useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch} from 'react-router-dom';

//Components
import HeaderComponent from './2_components/3_elements/header/header.component';
import NewsPage from './1_pages/news/news.component';
import ProjectPage from './1_pages/project/project.component';
import { BaseStateProvider } from './3_state/BaseState/BaseState.context';
import { MediaStateProvider } from './3_state/MediaState/MediaState.context';
import ContactPage from './1_pages/contact/contact.component';
import AboutPage from './1_pages/about/about.component';
import LoadingScreenComponent from './2_components/3_elements/loading-screen/loading-screen.component';
import HomePage from './1_pages/home/home.component';

function App() {

  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {

    window.addEventListener('load', () => {
      setTimeout(() => {
        document.querySelector('.sm-loading__bar__progress').style.width = '100%';
        document.querySelector('.sm-loading__bar__progress').style.animation = 'none';
      }, 1000)
      setTimeout(() => {
        setContentLoaded(true);
      }, 1200)
    })


    return () => {

    }
  }, [])

  return (
    <BaseStateProvider>
      <MediaStateProvider>
        <div className="App">
          <BrowserRouter>
            <HeaderComponent/>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/news" component={NewsPage} />
              <Route exact path="/kontakt" component={ContactPage} />
              <Route exact path={`/about`} component={AboutPage} />
              {/* <Route exact path="/projects/:cat/" component={ProjectPage} /> */}
              <Route exact path="/projekte/:cat/:id" onChange={() => { console.log('change')}} component={ProjectPage} />
            </Switch>
            { !contentLoaded ? 
              <LoadingScreenComponent/>
            :
              ''
            }
          </BrowserRouter>
        </div>
      </MediaStateProvider>
    </BaseStateProvider>
  );
}

export default App;
