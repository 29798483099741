import BASE_STATE_ACTIONS from './BaseState.actions';

const baseStateReducer = (state, action) => {
    switch (action.type) {
        case BASE_STATE_ACTIONS.TOGGLE_LANGUAGE:
            return {
                ...state,
                lang: state.lang == 'en' ? 'de' : 'en'
            }
        case BASE_STATE_ACTIONS.SET_BASE_STATE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default baseStateReducer;